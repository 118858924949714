import React from 'react';
import { Link } from 'gatsby';
import { MainLayout } from '../components/Layout';
import AddressSearch from '../components/AddressSearch/AddressSearch';
import {
  randomInt,
  slashPrefix,
  sliceIntoNArrays,
  truncateString,
} from '../utils/utils';
import ZoneMap from '../components/Map/ZoneMap';
import { navigate } from '@reach/router';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

export default ({ data, pageContext }) => {
  const zoneData = pageContext.zone;
  const zoneStreets = pageContext.zoneStreets;

  // Set if using a zone#street page
  const street = pageContext.street;

  const servicesCategories = pageContext.servicesCategories;
  const itemsCategories = pageContext.itemsCategories;
  const marketProducts = pageContext.marketProducts;

  const title = street
    ? `${street.streetName} dans le quartier ${zoneData.name} à ${street.city}`
    : `${zoneData.name} à ${zoneData.region}`;

  const description = street
    ? `Rejoignez vos voisins ${street.streetName} dans le quartier ${zoneData.name} à ${street.city}`
    : `Rejoignez vos voisins du quartier ${zoneData.name} à ${zoneData.region}`;

  return (
    <MainLayout siteTitle={title} siteDescription={description}>
      <section className="container section-zone">
        <div className="row">
          <div className="col-12 text-align-center">
            {street ? (
              <div className="roboto bold mt-10 mt-5-md pl-2-md pr-2-md font-size-xxl font-size-xl-md">
                Vous habitez{' '}
                <Link to={`${street.slug}/`} className="text-decoration-none">
                  <span className="yellowTag">{street.streetName}</span>
                </Link>{' '}
                à {street.city} ?
              </div>
            ) : (
              <div className="roboto bold mt-10 mt-5-md pl-2-md pr-2-md font-size-xxl font-size-xl-md">
                Vous habitez dans le quartier{' '}
                <span className="yellowTag">{zoneData.name}</span> à{' '}
                <span className="yellowTag">{zoneData.region}</span> ?
              </div>
            )}
          </div>
        </div>

        <div className="row mt-10">
          {street ? (
            <div className="col-12 font-size-xl text-align-center bold roboto pl-2-md pr-2-md">
              Rejoignez vos voisins du quartier{' '}
              <Link
                to={`/quartiers${slashPrefix(zoneData.slug)}/`}
                className="text-decoration-none"
              >
                <span className="yellowTag">{zoneData.name}</span>
              </Link>{' '}
              !
            </div>
          ) : (
            <div className="col-12 font-size-xl text-align-center bold roboto pl-2-md pr-2-md">
              Rejoignez vos voisins !
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-4 hidden-sm"></div>
          <div className="col-4 content col-12-sm pl-2-md pr-2-md">
            {street?.properties ? (
              <div>
                <div className="font-size-md mt-10 text-align-center font-size-sm-md pb-3">
                  Sélectionnez votre lieu de résidence
                </div>
                <div className="select-container">
                  <select
                    onChange={(e) =>
                      navigate('/inscription/form/', {
                        state: {
                          property: street.properties.find(
                            (property) => property.id === e.target.value,
                          ),
                          zone: zoneData,
                        },
                      })
                    }
                  >
                    <option value="">--</option>
                    {street.properties
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((p) => (
                        <option value={p.id}>{p.name}</option>
                      ))}
                  </select>
                </div>
                <div className="mt-5 font-size-md text-align-center font-size-sm-md">
                  Ou
                </div>
                <div className="mt-5 font-size-md text-align-center font-size-sm-md pb-3">
                  Saisissez une autre adresse postale
                </div>
              </div>
            ) : (
              <div className="font-size-md text-align-center font-size-sm-md pb-3">
                Saisissez votre adresse postale pour démarrer votre inscription
              </div>
            )}

            <AddressSearch
              id="addressSearch"
              zoneId={zoneData.id}
              prefillSearch={
                street ? `${street.streetName}, ${street.city}` : ''
              }
              placeholder={
                street
                  ? `${street.streetName}, ${street.city}`
                  : 'Mon adresse postale'
              }
            />
          </div>
          <div className="col-4 hidden-sm"></div>
        </div>

        <div className="row mt-10 mt-5-md">
          <div className="col-4 hidden-sm"></div>
          <div className="col-4 col-12-sm">
            <div className="zone-map text-align-right text-align-center-md pl-5-md pr-5-md mt-10-md">
              <ZoneMap
                zoneCoordinates={zoneData.location?.coordinates}
                propertyLocation={null}
              />
            </div>
          </div>
          <div className="col-4 hidden-sm"></div>
        </div>

        <div className="row mt-10">
          <div className="pt-2 text-color-purple-grey pl-2-md pr-2-md">
            <div className="font-size-md2 bold pb-1">
              <Link to={`/quartiers${slashPrefix(zoneData.slug)}/`}>
                Quartier {zoneData.name} à {zoneData.region} (
                {zoneStreets.length})
              </Link>
            </div>

            <hr />

            {sliceIntoNArrays(zoneStreets, 4).map((streets, index) => (
              <div className="col-3 pt-1 font-size-sm">
                <ul className="full-width">
                  {streets.map((value, index) => (
                    <li className="pb-2">
                      <Link to={`${value.slug}/`} activeClassName="bold">
                        <span className="pr-1">
                          {value.streetName +
                            ' à ' +
                            value.city +
                            ' (' +
                            value.postalCode +
                            ')'}
                        </span>
                      </Link>

                      {street &&
                      value.streetName === street.streetName &&
                      street.properties ? (
                        <ul className="mt-1 pl-2 pb-5">
                          {street.properties
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((p) => (
                              <li className="pb-2">
                                <Link
                                  to={`/ma-residence${slashPrefix(p.slug)}/`}
                                >
                                  {p.name}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      ) : (
                        ''
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="row mt-10">
          <div className="pt-2 text-color-purple-grey pl-2-md pr-2-md">
            <div className="font-size-md2 bold pb-1">
              Petites annonces entre voisins dans le quartier{' '}
              <Link to={`/quartiers${slashPrefix(zoneData.slug)}/`}>
                {zoneData.name}
              </Link>{' '}
              à {zoneData.region}
            </div>
            <hr />

            {!!marketProducts?.length && (
              <div className="row mt-10 mt-5-md">
                {marketProducts.map((product) => {
                  let randomIconNumber = randomInt(1, 6);

                  return (
                    <div className="col-3 col-12-sm">
                      <div className="box-shadow ml-4 mr-4 p-4 mb-4">
                        <div className="roboto">
                          <div className="float-left pt-1 mr-2">
                            <img
                              width={20}
                              height={20}
                              src={`${process.env.GATSBY_STATIC_URL}/avatars/${randomIconNumber}/icon.png`}
                              alt="utilisateur Alacaza"
                            />
                          </div>
                          <div className="bold font-size-md2 min-height-50">
                            {truncateString(product.title, 40)}
                          </div>
                          <div className="font-size-sm">
                            {product.property.publicName}
                          </div>
                        </div>
                        <div className="font-size-xs pt-2">
                          Publié le{' '}
                          {moment(product.createdAt).format('D MMMM YYYY')}
                        </div>
                        <div className="font-size-md mt-4 min-height-90">
                          {truncateString(product.description, 120)}
                        </div>

                        <div className="mt-2 font-size-md text-align-right">
                          <Link
                            to={'/inscription/'}
                            className="yellowTag bold cursor-pointer"
                          >
                            Ça m'intéresse !
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {sliceIntoNArrays(servicesCategories, 2).map(
              (categories, index) => (
                <div className="col-3 pt-1">
                  <ul className="full-width">
                    {categories.map((value, index) => (
                      <li className="pb-2 pr-2">
                        <div className="font-size-md">
                          <Link
                            to={
                              '/petites-annonces/services' +
                              slashPrefix(value.slug) +
                              (street === null
                                ? slashPrefix(zoneData.slug)
                                : slashPrefix(street.slug)) +
                              '/'
                            }
                            activeClassName="bold"
                          >
                            Services > {value.name}
                          </Link>
                        </div>
                        <div className="font-size-xs pt-1">
                          <Link
                            to={
                              '/petites-annonces/services' +
                              slashPrefix(value.slug) +
                              (street === null
                                ? slashPrefix(zoneData.slug)
                                : slashPrefix(street.slug)) +
                              '/'
                            }
                            activeClassName="bold"
                          >
                            {value.seoTitle} dans le quartier {zoneData.name}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ),
            )}

            {sliceIntoNArrays(itemsCategories, 2).map((categories, index) => (
              <div className="col-3 pt-1">
                <ul className="full-width">
                  {categories.map((value, index) => (
                    <li className="pb-2 pr-2">
                      <div className="font-size-md">
                        <Link
                          to={
                            '/petites-annonces/biens' +
                            slashPrefix(value.slug) +
                            (street === null
                              ? slashPrefix(zoneData.slug)
                              : slashPrefix(street.slug)) +
                            '/'
                          }
                          activeClassName="bold"
                        >
                          Biens > {value.name}
                        </Link>
                      </div>
                      <div className="font-size-xs pt-1">
                        <Link
                          to={
                            '/petites-annonces/biens' +
                            slashPrefix(value.slug) +
                            (street === null
                              ? slashPrefix(zoneData.slug)
                              : slashPrefix(street.slug)) +
                            '/'
                          }
                          activeClassName="bold"
                        >
                          {value.seoTitle} dans le quartier {zoneData.name}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
